.blog {
    height: 12rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 2.2rem 0rem;
    /* background-color: royalblue; */
}

.blog:hover .textContainer {
    font-size: 1.2rem;
    transition: 200ms;
}

.blog:hover .img {
    height: 90%;
    transition: 200ms;
}

.title {
    color: #F2786D;
    width: 100%;
    margin-bottom: 0.5rem;
}

a {
    text-decoration: none;
    outline: 0;
}

.textContainer {
    padding: 0.5rem;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.img {
    border-radius: 10px;
    width: 30%;
    height: 80%;
    opacity: 0.8;
    object-fit: cover;
}

.date {
    font-size: 0.8rem;
    color: grey;
}