.logo{
    width: 100px;
    /* padding: 10px; */
}
  
 .a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
  }
  
.a:hover {
    color: #F2786D;
  }
.links{
  display: flex;
  flex-direction: column;
}
.link{
  /* margin-top: 1rem; */
  /* background-color: rebeccapurple; */
  padding: 1rem;
}
.link:hover{
  background-color: #ededed;
}
.container{
  /* background-color: #F2786D; */
  max-width: 90%;
  display: flex;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
}