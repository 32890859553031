
.bubble {
    max-height: 232px;
    padding-inline-start: 60px;
    padding-inline-end: 50px;
    padding-block-end: 50px;
    padding-block-start: 20px;
    border-start-end-radius: 156px;
    border-start-start-radius: 156px;
    border-end-start-radius: 156px;
    background-color: #f2786d;
    color: white;
    margin: 3rem 0rem;
}
.bubble h1 {
    color: white;
    font-weight:500;
    margin-bottom: 10px;
    margin-top: 1rem;
    font-size: 1.7rem;
}
.bubble p{
    color: white;

}