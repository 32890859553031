.breadcrumps{
    margin-bottom: 3rem;
    /* border-bottom: 1px solid #ededed; */
    padding-bottom: 0.4rem;
    display: flex;
    
}
.seperator{
    margin: 0rem 0.5rem;
}
.link:hover{
    color:#F2786D;
    cursor: pointer;
    transition: 200ms;

}
.link{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40vw;
    /* background-color: red; */
}
.linksContainer{
    /* background-color: red; */
    display: flex;
}