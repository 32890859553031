.box{
    width: 100%;
    height: 22rem; 
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content:end;
    align-items: center;
    cursor: pointer;
}   
.box:hover  .textContainer{
    background-color: #ca655c;
    /* color: white; */
    transition: 200ms;
}

.title{
    color: white;
}
.description{
    color: white;
    margin: 0.5rem 0;
}
.textContainer{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    background-color: #f2786d;
    padding: 1.5rem;
    border-end-end-radius: 50px;
    border-end-start-radius: 50px;
    border-start-end-radius: 50px;
    width: 100%;
}
