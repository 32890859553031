form{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    width: 100%;

}
.email, .pass{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    width:90%
}
.input{
    height: 2.5rem;
    border-radius: 50px;
    border-color: #D9D9D9;
    padding: 5px 20px;
    border-style:solid;
    width: 100%;
}

label{
    margin-bottom: 0.5rem;
    margin-left: 5px;
}
.btn{
    margin-top: 1rem;
    width: 50%;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
    background-color: #F2786D;
    border: 0;
    border-radius: 50px;
}
.input:active{
border: 1px solid #F2786D;
}

.loginContainer{
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error{

    /* background-color: #F2786D; */
    width: 70%;
    /* display: flex; */
    /* justify-content: center; */
}