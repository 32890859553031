@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand";
  color: #333;
}


.content {
  width: 90%;
  margin: auto;
  margin-top: 5rem;
  padding: 20px;
  margin-bottom: 4rem;
  flex-grow: 1;

}

.app{
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.not-found{
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: center; 
  height: 30vh;
  align-items: center;
}