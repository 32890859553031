.bubble{
    margin-bottom: 11rem;
}
.project{
    margin-bottom: 11rem;
}
.blog{
    margin-bottom: 11rem;

}
.title{
    width: fit-content;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}
hr{
    width: 50%;
    border: 1px solid #ededed;
}
.titleText{
    margin-inline-end: 0.5rem;
    margin-inline-start: 0.5rem;
    color: #f2786d;
    font-size: 1.8rem;

}
.me{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 11rem;

}
.icons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 3rem 0;
}

.a {
    margin: 0rem 1.6rem;
}
.icon {
    fill: #ededed;
    color: #ededed;
    cursor: pointer;
    font-size: 1.7rem;
}

.icon:hover {
    fill: #ca655c;
    color: #ca655c;
    transition: 200ms;

}