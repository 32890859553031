.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    height: 15vh;
}

.text {
    color: #f2786d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.love {
    fill: #f2786d;
    color: #f2786d;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.icons {
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.5rem;
}

.a {
    margin: 0rem 2.5rem;
}
.icon {
    /* color:  */
    fill: #ededed;
    color: #ededed;
    cursor: pointer;
    font-size: 1.7rem;
    /* padding: 1rem; */
}

.icon:hover {
    fill: #ca655c;
    color: #ca655c;
    transition: 200ms;

}