.body{
    line-height: 1.5rem;
    padding: 1.5rem 0rem;
    display: flex;
    flex-direction: column;
}
.date{
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: grey;
}
.divider{
    margin-top: 0.5rem;
    border: 0;
    border-bottom: 1px solid #ededed;
}
.container{
    /* padding: 1rem 0rem; */
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
}
.share{
    margin-top: 1rem;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    color: #F2786D;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #F2786D;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;  
    max-width: 400px;
    align-self: center;
}

.share:hover{
    background-color: #ca655c;
    transition: 200ms;
    color: white;
    border: 0;
}
.snackMessage{
    font-weight: 500;
    width: 100%;
}
 .img{
    object-fit:cover !important;
}
.imgListContainer{
    /* background-color: rebeccapurple; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.container span{
    opacity: 1 !important;
    color: #F2786D !important;
    height: fit-content !important;
    /* background-color: red; */
}
.container span:hover{
    color: #ca655c !important;

}

.body img{
    object-fit:contain;
    margin: 1rem 0 !important;
    max-width: 600px !important;
    height: auto !important;
    align-self: center !important;
    /* max-height: 50vh; */
    width: 100% !important;
    /* width: auto; */
    /* width: 50% !important; */
    /* height: 30% !important; */
    border: 3px solid #ededed;

}
.body a {
    color:#F2786D
}
.body a:hover {
    color:#ca655c;
    transition: 200ms;
}